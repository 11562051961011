import React from 'react'
import './footer.css'

export default function Footer() {
  return (
    <div>
        <div className="footer">
            <h3 className='fText'>Designed & Developed by Cameron Ball</h3>
        </div>
    </div>
  )
}
